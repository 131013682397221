import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/unittestbot.github.io/unittestbot.github.io/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Is it OK for you to `}<strong parentName="p">{`simply get the latest version`}</strong>{` of UnitTestBot? Install it from `}<a parentName="p" {...{
        "href": "https://github.com/UnitTestBot/UTBotJava/wiki/Install-or-update-plugin#install-from-marketplace-in-intellij-idea"
      }}>{`Marketplace in IntelliJ IDEA`}</a>{`.`}</p>
    <p>{`Do you want to `}<strong parentName="p">{`manually choose the build`}</strong>{`? Install the plugin from the `}<a parentName="p" {...{
        "href": "https://github.com/UnitTestBot/UTBotJava/wiki/Install-or-update-plugin#install-from-downloaded-archive"
      }}>{`downloaded archive`}</a>{`.`}</p>
    <p>{`The same approach is applicable for `}<a parentName="p" {...{
        "href": "https://github.com/UnitTestBot/UTBotJava/wiki/Install-or-update-plugin#how-to-update-plugin"
      }}>{`updating`}</a>{` the plugin.`}</p>
    <hr></hr>
    <h2 {...{
      "id": "install-from-marketplace-in-intellij-idea"
    }}>{`Install from Marketplace in IntelliJ IDEA`}</h2>
    <ol>
      <li parentName="ol">{`In your IntelliJ IDEA go to `}<strong parentName="li">{`File`}</strong>{` > `}<strong parentName="li">{`Settings...`}</strong>{` > `}<strong parentName="li">{`Plugins`}</strong>{` and choose `}<strong parentName="li">{`Marketplace`}</strong>{`.`}</li>
      <li parentName="ol">{`In the search field type `}<em parentName="li">{`UnitTestBot`}</em>{` — you'll see the UnitTestBot plugin page.`}</li>
      <li parentName="ol">{`Press `}<strong parentName="li">{`Install`}</strong>{` and wait until it changes to `}<strong parentName="li">{`Installed`}</strong>{`, then click `}<strong parentName="li">{`OK`}</strong>{`.`}</li>
    </ol>
    <p>{`To check if UnitTestBot is enabled go to `}<strong parentName="p">{`File`}</strong>{` > `}<strong parentName="p">{`Settings...`}</strong>{` > `}<strong parentName="p">{`Plugins`}</strong>{` and choose `}<strong parentName="p">{`Installed`}</strong>{`.`}</p>
    <hr></hr>
    <h2 {...{
      "id": "install-from-downloaded-archive"
    }}>{`Install from downloaded archive`}</h2>
    <ol>
      <li parentName="ol">{`To get the latest stable build of UnitTestBot, go to `}<a parentName="li" {...{
          "href": "https://github.com/UnitTestBot/UTBotJava/releases"
        }}>{`GitHub`}</a>{`,
scroll through the release notes and click `}<strong parentName="li">{`Assets`}</strong>{`.`}<br></br>{`
Download the zip-archive named like `}<strong parentName="li">{`utbot-intellij-VERSION`}</strong>{`.`}<br></br>{`
The builds are also available at `}<a parentName="li" {...{
          "href": "https://plugins.jetbrains.com/plugin/19445-unittestbot/versions"
        }}>{`JetBrains Marketplace`}</a>{`.`}</li>
      <li parentName="ol">{`In your IntelliJ IDEA go to `}<strong parentName="li">{`File`}</strong>{` > `}<strong parentName="li">{`Settings...`}</strong>{`.`}</li>
      <li parentName="ol">{`Select `}<strong parentName="li">{`Plugins`}</strong>{`, click the gear icon and then `}<strong parentName="li">{`Install Plugin from Disk`}</strong>{`.`}</li>
      <li parentName="ol">{`Select the plugin archive file and click `}<strong parentName="li">{`OK`}</strong>{`.`}</li>
      <li parentName="ol">{`On `}<strong parentName="li">{`Plugins`}</strong>{` click `}<strong parentName="li">{`OK`}</strong>{` to apply the changes and restart your IDE if prompted.`}</li>
    </ol>
    <p>{`To check if UnitTestBot is enabled go to `}<strong parentName="p">{`File`}</strong>{` > `}<strong parentName="p">{`Settings...`}</strong>{` > `}<strong parentName="p">{`Plugins`}</strong>{` and choose `}<strong parentName="p">{`Installed`}</strong>{`.`}</p>
    <hr></hr>
    <h2 {...{
      "id": "how-to-update-plugin"
    }}>{`How to update plugin`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`To `}<em parentName="p">{`simply get the latest version`}</em>{` you can update plugin in your IntelliJ IDEA.`}<br></br>{`
Go to `}<strong parentName="p">{`File`}</strong>{` > `}<strong parentName="p">{`Settings...`}</strong>{` > `}<strong parentName="p">{`Plugins`}</strong>{`, choose `}<strong parentName="p">{`Installed`}</strong>{` and update UnitTestBot if available.`}<br></br>{`
`}<em parentName="p">{`Note:`}</em>{` the unused libraries will be removed automatically, there is no need to uninstall the previous version of the plugin.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`To `}<em parentName="p">{`choose the version`}</em>{` you can manually download the archived build and install it as described in `}{`[Install from downloaded archive]`}{`(#Install from downloaded archive).`}<br></br>{`
Note: before updating, please remove the previous version of the plugin. See `}{`[How to uninstall plugin]`}{`(#How to uninstall plugin).`}</p>
      </li>
    </ul>
    <hr></hr>
    <h2 {...{
      "id": "how-to-uninstall-plugin"
    }}>{`How to uninstall plugin`}</h2>
    <p>{`Go to `}<strong parentName="p">{`File`}</strong>{` > `}<strong parentName="p">{`Settings...`}</strong>{` > `}<strong parentName="p">{`Plugins`}</strong>{` and choose `}<strong parentName="p">{`Installed`}</strong>{`. Find UnitTestBot plugin and choose the gear icon to the right of the plugin name. Click `}<strong parentName="p">{`Uninstall`}</strong>{`, then click `}<strong parentName="p">{`Apply`}</strong>{`. Restart if prompted.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      